<template>
  <section class="intro-x my-5">
    <div class="w-full mb-5 flex">
      <back-button />
      <h1 class="font-bold text-lg pl-3">
        Detalle de la Solicitud
      </h1>
    </div>
    <div class="w-full mb-5">
      <div>
        <div>({{getRequest.stateLast}}). {{getRequestDomain(getRequest.stateLast, 'name')}} </div>
        <div class="item" :class="getRequestDomain(getRequest.stateLast, 'cssClass')" />
      </div>
    </div>
    <div class="w-full flex mb-5">

      <!-- <template v-if="($can(getCan.canGuardar) && $can(getCan.canConfirmar) && $can(getCan.canAnular) && getRequest.stateLast === 1)  ||  ($can(getCan.canGuardar) && $can(getCan.canAprovisionarProveedor) && $can(getCan.canEnCamino) && getRequest.stateLast === 4 ) ">
        <button
        v-if="getRequest.stateLast === 1 || getRequest.stateLast === 4"
        @click="onSubmit(1)"
        type="button"
        class="btn btn-primary ml-2"
        >
          Guardar
        </button>
      </template> -->
      <template v-if="$can(getCan.canConfirmar)">
        <button v-if="getRequest.stateLast === 1" class="btn btn-primary ml-2 capitalize" @click="onSubmit(2)">
          Confirmar
        </button>
      </template>
      <template v-if="$can(getCan.canAnular)">
        <button v-if="getRequest.stateLast === 1" class="btn btn-primary ml-2 capitalize" @click="onOpenDialog(3)">
          Anular
        </button>
      </template>
      <template v-if="$can(getCan.canValidarConsolidar)">
        <button v-if="getRequest.stateLast === 2" class="btn btn-primary ml-2 capitalize" @click="onSubmit(4)">
          Validar
        </button>
      </template>
      <template v-if="$can(getCan.canRechazar)">
        <button v-if="getRequest.stateLast === 2 || getRequest.stateLast === 6 || getRequest.stateLast === 10"
          class="btn btn-primary ml-2 capitalize" @click="onOpenDialog(5)">
          Rechazar Solicitud
        </button>
      </template>
      <template v-if="$can(getCan.canAprovisionarProveedor)">
        <button v-if="getRequest.stateLast === 4" class="btn btn-primary ml-2 capitalize" @click="onSubmit(6)">
          Aprovisionando con proveedor
        </button>
      </template>
      <template v-if="$can(getCan.canEnCamino)">
        <button v-if="getRequest.stateLast === 4 || getRequest.stateLast === 6" class="btn btn-primary ml-2 capitalize"
          @click="onSubmit(7)">
          En Camino
        </button>
      </template>
      <button v-if="getRequest.stateLast === 4 && $can(getCan.canEnCamino)" type="button" class="btn btn-danger ml-2"
        @click="handleDownloadPDF">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path fill="currentColor"
            d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M19 5V19H5V5H19M17.9 13.5C17.6 13 16.9 12.8 15.7 12.8C15.3 12.8 14.9 12.8 14.5 12.9C14.2 12.7 13.9 12.5 13.7 12.3C13.1 11.8 12.5 10.9 12.2 9.8V9.7C12.5 8.4 12.8 6.9 12.2 6.2C11.9 6.1 11.7 6 11.5 6H11.3C10.9 6 10.6 6.4 10.5 6.7C10.1 8 10.4 8.7 10.7 9.9C10.5 10.8 10.1 11.7 9.7 12.7C9.3 13.4 9 14 8.7 14.5C8.3 14.7 8 14.8 7.8 15C6.7 15.7 6.1 16.5 6 17V17.5L6.5 17.8C6.7 18 6.8 18 7 18C7.8 18 8.7 17.1 9.9 15H10C11 14.7 12.2 14.5 13.9 14.3C14.9 14.8 16.1 15 16.8 15C17.2 15 17.5 14.9 17.7 14.7C17.9 14.5 18 14.3 18 14.1C18 13.8 18 13.6 17.9 13.5M6.8 17.3C6.8 16.9 7.3 16.3 8 15.7C8.1 15.6 8.3 15.5 8.5 15.4C7.8 16.5 7.2 17.2 6.8 17.3M11.3 6.7C11.3 6.7 11.3 6.6 11.4 6.6H11.5C11.7 6.8 11.7 7.1 11.6 7.7V7.9C11.5 8.1 11.5 8.4 11.4 8.7C11.1 7.8 11.1 7.1 11.3 6.7M10.1 14.3H10C10.1 14 10.3 13.7 10.5 13.3C10.9 12.5 11.3 11.7 11.5 11C11.9 11.9 12.4 12.6 13 13.1C13.1 13.2 13.3 13.3 13.4 13.4C12.5 13.5 11.3 13.8 10.1 14.3M17.3 14.2H17.1C16.7 14.2 16 14 15.3 13.7C15.4 13.6 15.5 13.6 15.5 13.6C16.9 13.6 17.2 13.8 17.3 13.9L17.4 14C17.4 14.2 17.4 14.2 17.3 14.2Z" />
        </svg>
      </button>
      <button v-if="getRequest.stateLast === 7 && $can(getCan.canRecibirSede)" class="btn btn-primary ml-2 capitalize"
        @click="onSubmit(8)">
        Recibido por sede
      </button>
      <button v-if="getRequest.stateLast === 8 && $can(getCan.canConfirmaRecibido)"
        class="btn btn-primary ml-2 capitalize" @click="onSubmit(9)">
        Confirmar recibido
      </button>
      <button v-if="getRequest.stateLast === 10 && $can(getCan.canConfirmarEntrega)"
        class="btn btn-primary ml-2 capitalize" @click="onSubmit(11)">
        Confirmar entrega
      </button>
    </div>
    <template v-if="$can(getCan.canEnCamino)">
      <div class="w-full mb-3" v-if="getRequest.stateLast === 4 || getRequest.stateLast === 6">
        <span for="observations">Número de Guía </span>
        <input v-model="getRequest.numberGuide" type="text" class="form-control">
      </div>
    </template>
    <div class="w-full mb-3" v-if="getRequest.stateLast === 8 && $can(getCan.canConfirmaRecibido)">
      <span for="observations">Observaciones </span>
      <textarea id="observations" class="form-control" rows="4" v-model="getRequest.observations"></textarea>
    </div>
    <div class="w-full mb-3 overflow-x-auto">
      <DataTable :value="getDetails">
        <Column
          v-if="(getRequest.stateLast === 2 || getRequest.stateLast === 10) && ($can(getCan.canCantidadSolicitada) || $can(getCan.canCantidadAprobada))"
          headerStyle="width:30px;">
          <template #body="{ data }">
            <div>
              <button @click="onRemoveArticle(data)" type="button"
                class="bg-red-700 text-white rounded-md btn-remover py-1 px-1" title="Eliminar">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor"
                    d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                </svg>
              </button>
            </div>
          </template>
        </Column>
        <Column v-if="getRequest.type === 1 && $can(getCan.canGraficaTendencia)" header="Acciones"
          headerStyle="width:50px; text-align:center;">
          <template #body="{data}">
            <button v-if="$can(getCan.canGraficaTendencia) && data.id" class="btn btn-warning ml-1 p-1"
              title="Historico" @click="onOpenDialogHistory(data.id)">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor"
                  d="M15,4A8,8 0 0,1 23,12A8,8 0 0,1 15,20A8,8 0 0,1 7,12A8,8 0 0,1 15,4M15,6A6,6 0 0,0 9,12A6,6 0 0,0 15,18A6,6 0 0,0 21,12A6,6 0 0,0 15,6M14,8H15.5V11.78L17.83,14.11L16.77,15.17L14,12.4V8M2,18A1,1 0 0,1 1,17A1,1 0 0,1 2,16H5.83C6.14,16.71 6.54,17.38 7,18H2M3,13A1,1 0 0,1 2,12A1,1 0 0,1 3,11H5.05L5,12L5.05,13H3M4,8A1,1 0 0,1 3,7A1,1 0 0,1 4,6H7C6.54,6.62 6.14,7.29 5.83,8H4Z" />
              </svg>
            </button>
          </template>
        </Column>
        <Column header="Id" field="id" sortable headerStyle="width: 50px;" />
        <Column header="Producto" field="articlesPresentation.article.name" headerStyle="width: 240px;">
          <template #body="{ data }">
            <div v-if="(getRequest.stateLast === 2 || getRequest.stateLast === 10)">
              <div class="p-inputgroup">
                <AutoComplete v-model="data.detail" :suggestions="filteredProducts" @complete="searchProduct($event)"
                  @item-select="selectProduct($event, data._key)" field="codeName" inputClass="form-control">
                  <template #item="{ item }">
                    <div>
                      <span>{{item.codeName}}</span>
                    </div>
                  </template>
                </AutoComplete>
              </div>
              <small
                class="p-error">{{errorsUpdate[`producto_${data._key}`]?errorsUpdate[`producto_${data._key}`]:""}}</small>
            </div>
            <span v-else>{{data.articlesPresentation.article.name}}</span>
          </template>
        </Column>
        <!-- <Column header="Producto" sortable field="articlesPresentation.article.name"  headerStyle="width: 240px;" /> -->
        <!-- <Column header="Presentacion" sortable field="articlesPresentation.presentation.name" headerStyle="width: 150px;" /> -->
        <Column header="Cantidad Solicitada" sortable headerStyle="width: 150px;">
          <template #body="{ data }">
            <div
              v-if="(getRequest.stateLast === 2 || getRequest.stateLast === 10) && $can(getCan.canCantidadSolicitada)">
              <div class="p-inputgroup">
                <input @keyup="data.requestAmount=$h.onlyNumber($event.target.value)" type="number" class="form-control"
                  v-model="data.requestAmount">
              </div>
              <small
                class="p-error">{{errorsUpdate[`requestAmount_${data._key}`]?errorsUpdate[`requestAmount_${data._key}`]:""}}</small>
            </div>
            <span v-else>{{data.requestAmount}}</span>
          </template>
        </Column>
        <Column v-if="getRequest.stateLast >= 2 && getRequest.stateLast < 10 && $can(getCan.canCantidadAprobada)"
          header="En Inventrio" sortable field="articlesPresentation.inventary.stock" headerStyle="width: 150px;">
          <template #body="{data}">
            <span v-if="data.articlesPresentation">
              {{data.articlesPresentation.inventary?data.articlesPresentation.inventary.stock:0}}
            </span>
            <span v-else>0</span>
          </template>
        </Column>
        <Column header="Cantidad Aprobada" sortable field="approveAmount" headerStyle="width: 150px;"
          v-if="(getRequest.stateLast >= 2 && getRequest.stateLast < 10 && $can(getCan.canCantidadAprobada)) || getRequest.stateLast >= 4 && getRequest.stateLast < 10">
          <template #body="{data}">
            <div v-if="getRequest.stateLast === 2 && $can(getCan.canCantidadAprobada)">
              <div class="p-inputgroup">
                <input @keyup="data.approveAmount=$h.onlyNumber($event.target.value)" type="number" class="form-control"
                  v-model="data.approveAmount">
              </div>
              <small
                class="p-error">{{errorsUpdate[`approveAmount_${data._key}`]?errorsUpdate[`approveAmount_${data._key}`]:""}}</small>
            </div>
            <span v-else>
              {{data.approveAmount?data.approveAmount:0}}
            </span>
          </template>
        </Column>
        <Column header="Cantidad Recibida" sortable field="receiveAmount" headerStyle="width: 150px;"
          v-if="getRequest.stateLast >= 8 && getRequest.stateLast < 10 || (getRequest.stateLast === 8 && $can(getCan.canConfirmaRecibido))">
          <template #body="{data}">
            <div v-if="getRequest.stateLast === 8 && $can(getCan.canConfirmaRecibido)">
              <div class="p-inputgroup">
                <input @keyup="data.receiveAmount=$h.onlyNumber($event.target.value)" type="number" class="form-control"
                  v-model="data.receiveAmount">
              </div>
              <small
                class="p-error">{{errorsUpdate[`receiveAmount_${data._key}`]?errorsUpdate[`receiveAmount_${data._key}`]:""}}</small>
            </div>
            <span v-else>
              {{data.receiveAmount}}
            </span>
          </template>
        </Column>
        <Column header="Obervaciones" sortable headerStyle="width: 400px;"
          v-if="getRequest.stateLast >= 8 && $can(getCan.canConfirmaRecibido) && getRequest.stateLast < 10">
          <template #body="{data}">
            <input v-if="getRequest.stateLast === 8" type="text" v-model="data.receiveObservation" class="form-control">
            <span v-else>{{data.receiveObservation}}</span>
          </template>
        </Column>
        <Column header="Solicitado Por" field="userRequest.full_name" sortable headerStyle="width: 150px;" />
        <Column v-if="getRequest.stateLast >= 4 && (getRequest.stateLast < 10 || getRequest.stateLast === 11)  "
          header="Aprobado Por" field="userApprove.full_name" sortable headerStyle="width: 150px;" />
        <Column v-if="getRequest.stateLast == 9" header="Recibido Por" field="userReceive.full_name" sortable
          headerStyle="width: 150px;" />
        <Column v-if="getRequest.type === 1" header="" headerStyle="width: 50px;">
          <template #body="{data}">
            <div v-if="$can(getCan.canIndicadoresTendencia) && data.id">
              <svg v-if="onGetFrequencyAmount(data.id) > data.requestAmount" style="width:24px;height:24px"
                viewBox="0 0 24 24">
                <path fill="currentColor" d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z" />
              </svg>
              <svg v-else-if="onGetFrequencyAmount(data.id) === data.requestAmount" style="width:24px;height:24px"
                viewBox="0 0 24 24">
                <path fill="currentColor"
                  d="M4,12H20V14H4V12M4,9H20V11H4V9M16,4L12,8L8,4H11V1H13V4H16M8,19L12,15L16,19H13V22H11V19H8Z" />
              </svg>
              <svg v-else-if="onGetFrequencyAmount(data.id) < data.requestAmount" style="width:24px;height:24px"
                viewBox="0 0 24 24">
                <path fill="currentColor"
                  d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" />
              </svg>
            </div>
          </template>
        </Column>
      </DataTable>
      <small class="p-error">{{errorMostInventary}}</small>
      <small class="p-error">{{errorDuplicate}}</small>
    </div>
    <div v-if="(getRequest.stateLast === 2 || getRequest.stateLast === 10) && $can(getCan.canCantidadSolicitada)"
      class="mb-3">
      <button type="button" class="btn btn-primary mr-2" @click="onAddProduct">
        Agregar
      </button>
    </div>
    <div class="w-full my-5 flex justify-center">
      <div v-if="(getRequest.stateLast === 2 || getRequest.stateLast === 10)">
        <button class="btn btn-primary" @click="onSubmitEdit">
          Guardar
        </button>
      </div>
    </div>
  </section>
  <Dialog v-model:visible="dialogCancelRequest" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3 text-red-600" style="font-size: 2rem" />
      <span>
        ¿Está seguro de {{statusCancel===3?'anular':'rechazar'}} la solicitud # <b>{{getRequest.id}}</b>?
      </span>
      <div class="w-full mt-3">
        <label for="justification">Justificación</label>
        <div class="p-inputgroup">
          <textarea id="justification" rows="6" class="form-control" v-model="model.justification"></textarea>
        </div>
        <messageError :text="errors.justification" />
      </div>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text p-button-danger"
        @click="dialogCancelRequest = false" />
      <Button label="Si" icon="pi pi-check" class="p-button-text p-button-success" @click="onSubmitAnular()" />
    </template>
  </Dialog>
  <Dialog header="Historial solicitudes" v-model:visible="dialogHistoryRequest" :style="{width: '50vw'}" :modal="true">
    <div ref="elChart" :style="{width: '100%', height: '300px'}"></div>
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="dialogHistoryRequest=false" class="p-button-text" />
    </template>
  </Dialog>
  <Dialog header="Los siguientes articulos estan con cantidad superior a la que hay disponible en el inventario"
    v-model:visible="dialogErroresStockAprovadas" :style="{width: '50vw'}" :modal="true">
    <div class="w-full mb-3 overflow-x-auto">
      <DataTable :value="erroresStockAprovadas">
        <Column header="Id" field="id" sortable headerStyle="width: 50px;" />
        <Column header="Producto" field="articlesPresentation.article.name" headerStyle="width: 240px;" />
        <Column header="Cantidad Solicitada" field="requestAmount" sortable headerStyle="width: 150px;" />
        <Column header="En Inventario" sortable headerStyle="width: 150px;">
          <template #body="{data}">
            <span v-if="data.articlesPresentation">
              {{data.articlesPresentation.inventary?data.articlesPresentation.inventary.stock:0}}
            </span>
            <span v-else>0</span>
          </template>
        </Column>
        <Column header="Cantidad Aprobada" sortable field="approveAmount" headerStyle="width: 150px;" />
      </DataTable>
    </div>
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="dialogErroresStockAprovadas =false" class="p-button-text" />
    </template>
  </Dialog>
</template>

<script>
import requestsActualizar from '../../../../services/requestsActualizar'
import findNameArticle from '../../../../services/findNameArticle'
import articleInventaryFindService from '../../../../services/articleInventaryFindService'
import requestsDetailDelete from '../../../../services/requestsDetailDelete'
import requestsInventaryService from '../../../../services/requestsInventaryService'
import requestsBuscar from '../../../../services/requestsBuscar'
// import requestsHistoryAmountService from '../../../../services/requestsHistoryAmountService'
import requestsDetailsSave from '../../../../services/requestsDetailsSave'
import requestsStateUpdateService from '../../../../services/requestsStateUpdateService'
import { messageConfirm, messageSuccess } from '../../../../../../../libs/mensajes'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, computed, ref, reactive } from 'vue'
import { useForm, useField } from 'vee-validate'
import { object, string } from 'yup'
import can from '../can'
import * as echarts from 'echarts'
import sumBy from 'lodash/sumBy'
import isNumber from 'lodash/isNumber'
import { can as $c } from '../../../../../../../utils/permission'
import axios from 'axios'
import { funcion } from '../../../../../../../utils/funciones'
import requestDomain from '../../../../../../../domains/requestDomain'

// import echarts from 'echarts'

export default {
  name: 'detailSolicitud',
  components: {
    DataTable,
    Column
    // AutoComplete
  },
  setup () {
    const router = useRouter()
    const getCan = computed(() => can)

    const route = useRoute()
    const isEdit = computed(() => {
      if (route.params.id) return true
      return false
    })
    const campoValidate = ref('requestAmount')
    let chartInstance = reactive({})
    const elChart = ref(null)
    const statusCancel = ref(3)
    const errorMostInventary = ref('')
    const dialogCancelRequest = ref(false)
    const dialogHistoryRequest = ref(false)

    const errorsUpdate = ref([])
    const filteredProducts = ref([])
    const errorDuplicate = ref('')

    const erroresStockAprovadas = ref([])
    const dialogErroresStockAprovadas = ref(false)

    const request = ref({
      id: null,
      stateLast: 0,
      observations: '',
      details: []
    })

    const getRequestDomain = (stateLast, key) => {
      return requestDomain[stateLast] ? requestDomain[stateLast][key] : ''
    }

    const getRequest = computed(() => {
      return request.value
    })

    const getDetails = computed(() => {
      return request.value.details
    })

    const validationSchema = object().shape({
      justification: string().required().nullable().label('justificación')
    })
    const { values: model, handleSubmit, handleReset, errors } = useForm({
      validationSchema
    })
    useField('justification', null, { initialValue: null })

    const onValidateInventary = (value, inventary) => {
      const stockInventary = inventary ? inventary.stock : 0
      if (value < stockInventary) return value
      return stockInventary
    }
    const onSubmitAnular = handleSubmit((values) => {
      dialogCancelRequest.value = false
      onSubmit(statusCancel.value)
    })
    const onValidateAmount = (stateCode) => {
      erroresStockAprovadas.value = []
      let valiAmount = 0
      let valiCount = 0
      if (stateCode === 6 || stateCode === 7) {
        const _key = stateCode === 6 || stateCode === 7 ? 'approveAmount' : 'receiveAmount'
        for (const detail of getDetails.value) {
          const amont = parseFloat(detail[_key])
          if (!amont) {
            valiCount++
          }
          if (stateCode === 7) {
            if (detail.articlesPresentation.inventary) {
              if (amont > detail.articlesPresentation.inventary.stock) {
                valiAmount++
                erroresStockAprovadas.value.push(detail)
              }
            } else {
              valiAmount++
              erroresStockAprovadas.value.push(detail)
            }
          }
        }
      }
      if (valiAmount) {
        errorMostInventary.value = 'Existen artículos con cantidad superior que está en inventario'
        dialogErroresStockAprovadas.value = true
      }
      return valiCount + valiAmount
    }

    const onSaveDetails = async () => {
      const { id } = route.params
      const payload = {
        numberGuide: getRequest.value.numberGuide,
        observations: getRequest.value.observations,
        details: getDetails.value
      }
      return await requestsDetailsSave(id, payload).then(({ data }) => {
        return data
      }).catch(err => {
        console.error(err.message)
      })
    }

    const confirmSubmitEdit = () => {
      if (!vaidateUpdateArticle()) return false
      if (!vaidateDuplicateArticle()) return false
      return true
    }

    const onSubmit = (stateCode = 0) => {
      if (stateCode === 0) return false
      if (onValidateAmount(stateCode)) return false
      if (!confirmSubmitEdit() && stateCode === 4) return false
      messageConfirm('no es posible deshacer esta accion').then(({ isConfirmed }) => {
        if (!isConfirmed) return false
        const { id } = route.params
        if (stateCode === 1) { // guardando la solicitud
          funcion.cargando()
          onSaveDetails().then(({ data }) => {
            funcion.cargando(0)
            messageSuccess('Solicitud guardada')
            router.back()
          })
        } else if (stateCode === 2) { // solicitar a sede
          requestsStateUpdateService(id, { state: 2, justification: 'solicitado a sede' }).then(() => {
            onSaveDetails().then(() => {
              fetchRequest()
              handleReset()
              messageSuccess('El estado de la solicitud ha sido actualizado')
              router.back()
            })
          }).catch(err => {
            console.error(err.message)
          })
        } else if (stateCode === 3) { // anulado por sede
          requestsStateUpdateService(id, { state: 3, justification: model.justification }).then(() => {
            messageSuccess('Solicitud anulado')
            fetchRequest()
            router.back()
          }).catch(err => {
            console.error(err.message)
          })
        } else if (stateCode === 4) { // validado y consolidado
          requestsActualizar(route.params.id, request.value).then(({ status }) => {
            if (status === 200) {
              requestsStateUpdateService(id, { state: 4, justification: 'validado y consolidado' }).then(() => {
                  errorDuplicate.value = ''
                  messageSuccess('Solicitud validado y consolidado')
                  fetchRequest()
                  router.back()
              }).catch(err => {
                console.error(err.message)
              })
            }
          })
        } else if (stateCode === 5) { // rechazar
          requestsStateUpdateService(id, { state: 5, justification: model.justification }).then(() => {
            fetchRequest()
            handleReset()
            messageSuccess('Solicitud rechazado')
            router.back()
          }).catch(err => {
            console.error(err.message)
          })
        } else if (stateCode === 6) { // aprovisionando con proveedor
          requestsStateUpdateService(id, { state: 6, justification: 'aprovisionando con proveedor' }).then(() => {
            onSaveDetails().then(() => {
              fetchRequest()
              handleReset()
              messageSuccess('Solicitud aprovisionando con proveedor')
              router.back()
            })
          }).catch(err => {
            console.error(err.message)
          })
        } else if (stateCode === 7) { // En camino
          requestsStateUpdateService(id, { state: 7, justification: 'En camino' }).then(() => {
            onSaveDetails().then(() => {
              requestsInventaryService(id, { details: getDetails.value }).then(res => {
                fetchRequest()
                handleReset()
                messageSuccess('Solicitud en camino')
                router.back()
              })
            })
          }).catch(err => {
            console.error(err.message)
          })
        } else if (stateCode === 8) { // Recibido por sede
          requestsStateUpdateService(id, { state: 8, justification: 'recibido por sede' }).then(result => {
            onSaveDetails().then(() => {
              fetchRequest()
              handleReset()
              messageSuccess('recibido por sede')
              router.back()
            })
          }).catch(err => {
            console.error(err.message)
          })
        } else if (stateCode === 9) { // confirmar recibido
          requestsStateUpdateService(id, { state: 9, justification: 'confirmar recibido' }).then(result => {
            onSaveDetails().then(() => {
              fetchRequest()
              handleReset()
              messageSuccess('confirmar recibido')
              router.back()
            })
          }).catch(err => {
            console.error(err.message)
          })
        } else if (stateCode === 11) { // confirmar entregado
          requestsStateUpdateService(id, { state: 11, justification: 'confirmar entregado' }).then(result => {
            onSaveDetails().then(() => {
              requestsInventaryService(id, { details: getDetails.value }).then(res => {
                fetchRequest()
                handleReset()
                messageSuccess('confirmar entregado')
                router.back()
              })
            })
          }).catch(err => {
            console.error(err.message)
          })
        }
      })
    }
    const onOpenDialog = (status) => {
      statusCancel.value = status
      dialogCancelRequest.value = true
    }
    const onOpenDialogHistory = (requestDetailId) => {
      dialogHistoryRequest.value = true
      const historyDetail = getRequest.value.historyDetails.find(f => f.id === requestDetailId)
      setTimeout(() => {
        tdrawLine(historyDetail.history)
      }, 200)
      // requestsHistoryAmountService(requestDetailId).then(({ data }) => {
      //   tdrawLine(data)
      // }).catch(err => {
      //   console.error(err.message)
      // })
    }
    const onGetFrequencyAmount = (requestDetailId) => {
      const historyDetail = getRequest.value.historyDetails.find(f => f.id === requestDetailId)
      const amountTotal = sumBy(historyDetail.history, 'receiveAmountMont')
      return amountTotal / historyDetail.history.length
    }
    const tdrawLine = (data) => {
      // Basado en el dom preparado, inicialice la instancia echarts
      chartInstance = echarts.init(elChart.value)
      // dibuja un gráfico
      chartInstance.setOption({
          title: { text: 'Historial últimos 10 meses de solicitudes' },
          tooltip: {},
          xAxis: {
              data: data.map(f => f.yearMonth)
              // data: ['camisa', 'Suéter', 'Camisa de gasa', 'pantalones', 'Tacones altos', 'calcetín']
          },
          yAxis: {},
          series: [{
              name: 'Cantidad',
              type: 'bar',
              data: data.map(f => parseFloat(f.receiveAmountMont))
              // data: [5, 20, 36, 10, 10, 20]
          }]
      })
      loadEvents()
    }
    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        // setChart()
        chartInstance.resize()
      })
    }
    const vaidateDuplicateArticle = () => {
      var vali = 0
      for (const v of request.value.details) {
        const itemVali = request.value.details.filter(f => f.detail.id === v.detail.id)
        vali = itemVali.length > 1 ? (vali + 1) : vali
      }
      errorDuplicate.value = ''
      if (vali) {
        errorDuplicate.value = 'Existen artículos duplicados'
        return false
      }
      return true
    }
    const vaidateUpdateArticle = () => {
      var vali = 0
      for (const v of request.value.details) {
        if (!v.detail) {
          errorsUpdate.value[`producto_${v._key}`] = 'producto es un campo obligatorio'
          vali = vali + 1
        } else {
          if (!v.detail.id) {
            errorsUpdate.value[`producto_${v._key}`] = 'producto es un campo obligatorio'
            vali = vali + 1
          } else {
            errorsUpdate.value[`producto_${v._key}`] = ''
          }
        }
        if (!v[campoValidate.value]) {
          errorsUpdate.value[`${campoValidate.value}_${v._key}`] = 'cantidad del producto es un campo obligatorio'
          vali = vali + 1
        } else if (!isNumber(v[campoValidate.value])) {
          errorsUpdate.value[`${campoValidate.value}_${v._key}`] = 'cantidad del producto debe ser un número'
          vali = vali + 1
        } else {
          errorsUpdate.value[`${campoValidate.value}_${v._key}`] = ''
        }

        // if (campoValidate.value === 'requestAmount') {
        //   v.approveAmount = false
        // }
      }
      if (vali) {
        return false
      }
      return true
    }
    const onSubmitEdit = () => {
      if (!confirmSubmitEdit()) return false
      requestsActualizar(route.params.id, request.value).then(({ status }) => {
        if (status === 200) {
          errorDuplicate.value = ''
          messageSuccess('Solicitud actualizado')
          router.back()
        }
      }).catch(err => {
        console.error(err.message)
      })
    }
    const onRemoveArticle = (data) => {
      messageConfirm('no es posible deshacer esta accion').then(({ isConfirmed }) => {
        if (!isConfirmed) return false
        request.value.details.splice(data._key, 1)
        if (data.id) {
          requestsDetailDelete({ id: data.id }).then(res => {
            // messageSuccess('Artículo eliminado')
          }).catch(err => {
            console.error(err.message)
          })
        }
        for (const key in request.value.details) {
          request.value.details[key]._key = key
        }
      })
    }
    const onAddProduct = () => {
      request.value.details.push({
        id: null,
        presentation_id: null,
        detail: null,
        requestAmount: null,
        _key: request.value.details.length
      })
    }
    const searchProduct = ({ query }) => {
      if (!query.trim() || query.length < 3 || (getRequest.value.type !== 1 && getRequest.value.type !== 2)) {
        filteredProducts.value = []
      } else {
        if (getRequest.value.type === 1) {
          return findNameArticle({ name: query.trim() }).then(({ data }) => {
            filteredProducts.value = data
          }).catch(err => {
            console.error(err.message)
          })
        }
        return articleInventaryFindService({ name: query.trim() }).then(({ data }) => {
          filteredProducts.value = data
        }).catch(err => {
          console.error(err.message)
        })
      }
    }
    const selectProduct = ({ value }, _key) => {
      request.value.details[_key].detail = {
        id: value.id,
        name: value.name,
        codeName: value.codeName
      }
      request.value.details[_key].presentation_id = value.presentations.id
    }
    const handleDownloadPDF = () => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_SHOPS}/request-detail-pdf`
      const res = axios.getUri({ url, params: { id: route.params.id } })
      window.open(res, '_blank')
    }
    const setRequests = (value) => {
      request.value = value
      for (const key in value.details) {
        if (getRequest.value.stateLast === 2) {
          // se comenta esta linea por que no reflejaba el cambio de la cantidad aprobada
          request.value.details[key].approveAmount = request.value.details[key].approveAmount ? request.value.details[key].approveAmount : value.details[key].requestAmount
        } else if (getRequest.value.stateLast === 7 && value.details[key].receiveAmount) {
          value.details[key].receiveAmount = value.details[key].receiveAmount ? value.details[key].receiveAmount : value.details[key].approveAmount
        }
        const article = value.details[key].articlesPresentation.article
        request.value.details[key].presentation_id = value.details[key].articlePresentationId
        request.value.details[key]._key = key
        request.value.details[key].detail = { id: article.id, name: article.name, codeName: article.codeName }
      }
    }
    const fetchRequest = () => {
      return requestsBuscar(route.params.id).then(({ data }) => {
        setRequests(data)
      }).catch(err => {
        console.error(err.message)
      })
    }
    onMounted(() => {
      fetchRequest()
      if ($c(getCan.value.canCantidadSolicitada)) {
        campoValidate.value = 'requestAmount'
      } else if ($c(getCan.value.canCantidadAprobada)) {
        campoValidate.value = 'approveAmount'
      } else {
        campoValidate.value = 'receiveAmount'
      }
    })
    return {
      model,
      errors,
      isEdit,
      errorMostInventary,
      getRequest,
      getDetails,
      dialogCancelRequest,
      dialogHistoryRequest,
      statusCancel,
      onSubmit,
      onSubmitAnular,
      onValidateAmount,
      onValidateInventary,
      onOpenDialog,
      onOpenDialogHistory,
      getCan,
      elChart,
      onGetFrequencyAmount,
      onSubmitEdit,
      onRemoveArticle,
      onAddProduct,
      filteredProducts,
      searchProduct,
      selectProduct,
      errorDuplicate,
      errorsUpdate,
      handleDownloadPDF,
      dialogErroresStockAprovadas,
      erroresStockAprovadas,
      getRequestDomain

    }
  }
}
</script>

<style scoped>
.item {
  height: 15px;
  width: 30px;
  border-radius: 2px;
}

.tamano-texto {
  font-size: 12px;
}
</style>
